<template>
  <b-row class="bg-black-0 rounded pr-16 pr-sm-32 mx-0">
    <b-col
      cols="12"
      class="col-16 pl-sm-32 py-24 py-sm-32 overflow-hidden"
    >
      <h2>Tambah Vendor</h2>
      <b-form @submit.prevent="storeVendor">
        <b-col class="col-md-6">
          <b-form-group>
            <label>Nama Vendor</label>
            <b-form-input
              v-model="vendor.nama"
              type="text"
              :state="v$.vendor.nama.$dirty ? !v$.vendor.nama.$error : null"
              placeholder="Masukkan Nama Vendor"
            />
            <b-form-invalid-feedback :state="v$.vendor.nama.$dirty ? !v$.vendor.nama.$error : null">
              {{ errorMessages.nama }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col class="col-md-6">
          <b-form-group>
            <label>Alamat</label>
            <b-form-textarea
              v-model="vendor.alamat"
              type="text"
              :state="v$.vendor.alamat.$dirty ? !v$.vendor.alamat.$error : null"
              placeholder="Masukkan Alamat Vendor"
            />

            <b-invalid-form-feedback
              :state="v$.vendor.alamat.$dirty ? !v$.vendor.alamat.$error : null"
              style="color: red;"
            >
              {{ errorMessages.alamat }}
            </b-invalid-form-feedback>
          </b-form-group>
        </b-col>
        <b-col class="col-md-6">
          <b-form-group>
            <label>PIC</label>
            <b-form-input
              v-model="vendor.pic"
              type="text"
              :state="v$.vendor.pic.$dirty ? !v$.vendor.pic.$error : null"
              placeholder="Masukkan Nama PIC"
            />
            <b-form-invalid-feedback :state="v$.vendor.pic.$dirty ? !v$.vendor.pic.$error : null">
              {{ errorMessages.pic }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col class="col-md-6">
          <b-form-group>
            <label>Nomor Telepon</label>
            <b-form-input
              v-model="vendor.telp"
              type="number"
              :state="v$.vendor.telp.$dirty ? !v$.vendor.telp.$error : null"
              placeholder="Masukkan Nomor Telepon"
            />
            <b-form-invalid-feedback :state="v$.vendor.telp.$dirty ? !v$.vendor.telp.$error : null">
              {{ errorMessages.telp }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col class="col-md-6">
          <b-form-group>
            <label>Alamat Email Vendor</label>
            <b-form-input
              v-model="vendor.emailVendor"
              :state="v$.vendor.emailVendor.$dirty ? !v$.vendor.emailVendor.$error : null"
              type="email"
              placeholder="Masukkan Email Vendor"
            />
            <b-form-invalid-feedback :state="v$.vendor.emailVendor.$dirty ? !v$.vendor.emailVendor.$error : null">
              {{ errorMessages.emailVendor }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col class="col-md-6">
          <b-form-group>
            <label>Deskripsi</label>
            <b-form-textarea
              v-model="vendor.deskripsi"
              placeholder="Masukkan Deskripsi"
            />
          </b-form-group>
        </b-col>
        <b-col class="col-md-6">
<!--          <b-button-->
<!--            variant="primary-1 "-->
<!--            type="submit"-->
<!--            :loading="isBusy"-->
<!--          >-->
<!--            <b-spinner-->
<!--              v-if="isBusy"-->
<!--              small-->
<!--              label="Small Spinner"-->
<!--            />-->
<!--            <span v-else>Simpan</span>-->
<!--          </b-button>-->
          <base-button text="Simpan" :is-busy="isBusy" :loading="isBusy" variant="primary-1" submit-type="submit"></base-button>
          <b-button variant="danger-1 mx-2">
            Keluar
          </b-button>
        </b-col>
      </b-form>
    </b-col>
  </b-row>
</template>

<script>
import { required, minLength } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import {
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BRow,
  BCol,
  BFormTextarea,
  BButton, BSpinner,
} from 'bootstrap-vue'
import fieldMappingsErrorMsgVendor from '@/view/main/dashboards/managevendor/data/fieldMappingsErrorMsgVendor'

export default {
  setup: () => ({ v$: useVuelidate() }),
  name: 'AddVendor',
  components: {
    BSpinner,
    BRow,
    BCol,
    BForm,
    BFormInput,
    BFormGroup,
    BFormTextarea,
    BButton,
    BFormInvalidFeedback,
  },
  data() {
    return {
      vendor: {
        nama: '',
        alamat: '',
        pic: '',
        telp: '',
        emailVendor: '',
        deskripsi: '',
      },
      errorMessages: {},
    }
  },
  validations() {
    return {
      vendor: {
        nama: { required, minLength: minLength(3) },
        alamat: { required },
        pic: { required },
        telp: { required },
        emailVendor: { required },
      },
    }
  },
  computed: {
    isBusy() {
      return this.$store.state.manageVendor.isBusy
    },
  },
  methods: {
    async storeVendor() {
      const formData = new FormData()

      formData.append('name', this.vendor.nama)
      formData.append('address', this.vendor.alamat)
      formData.append('pic', this.vendor.pic)
      formData.append('phone', this.vendor.telp)
      formData.append('email', this.vendor.emailVendor)
      formData.append('description', this.vendor.deskripsi)

      await this.$store.dispatch('manageVendor/storeVendor', formData)
        .then(() => {
          this.$router.push({ name: 'listvendors' })

          this.$bvToast.toast('Data Vendor Berhasil Ditambahkan', {
            title: 'Success',
            variant: 'success',
            solid: true,
          })
        }).catch(err => {
          if (err.response && err.response.data && err.response.data.success === false && err.response.data.message) {
            const errorMessage = err.response.data.message
            Object.keys(this.vendor).forEach(field => {
              if (this.v$.vendor[field]) {
                this.v$.vendor[field].$error = true
                this.v$.vendor[field].$touch()
                this.$set(this.errorMessages, field, fieldMappingsErrorMsgVendor[field], errorMessage)
              }
            })
          }
        })
    },
  },
}
</script>
<style>

</style>
